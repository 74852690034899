define('essma/components/modal-dialog', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'div',
    classNames: ['modal', 'fade'],

    title: 'Default title',
    cancelButtonLabel: 'Cancel',
    primerButtonLabel: 'Save',

    actions: {
      primerButtonClicked: function primerButtonClicked() {
        this.sendAction();
      }
    }
  });
});