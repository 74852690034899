define('essma/controllers/ssdelegate/index', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    columns: computed(function () {
      return [{
        label: 'Name',
        valuePath: 'name',
        width: '150px'
      }, {
        label: 'Business Name',
        valuePath: 'business'
      }, {
        label: 'Position Title',
        valuePath: 'title',
        width: '150px'
      }];
    }),

    actions: {
      transitionToDetail: function transitionToDetail(row) {
        this.transitionToRoute('ssdelegate.show', row.get('content'));
      }
    }
  });
});