define('essma/routes/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    moment: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      this.get('session').fetch()['catch'](function () {});
      this.get('moment').setLocale('en');
      this.get('moment').setTimeZone('Australia/Perth');
    },

    actions: {

      signIn: function signIn(email, password) {
        this.get('session').open('firebase', { provider: 'password', email: email, password: password }).then(function (data) {
          return console.log(data.currentUser);
        }); //eslint-disable-line no-console
      },

      signOut: function signOut() {
        this.get('session').close();
      }
    }
  });
});