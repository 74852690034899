define('essma/helpers/humanize', ['exports', 'ember-cli-string-helpers/helpers/humanize'], function (exports, _emberCliStringHelpersHelpersHumanize) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersHumanize['default'];
    }
  });
  Object.defineProperty(exports, 'humanize', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersHumanize.humanize;
    }
  });
});