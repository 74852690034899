define('essma/router', ['exports', 'ember', 'essma/config/environment'], function (exports, _ember, _essmaConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _essmaConfigEnvironment['default'].locationType,
    rootURL: _essmaConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('about');

    this.route('business', function () {
      this.route('show', { path: '/:id' });
    });
    this.route('notification', function () {
      this.route('show', { path: '/:id' });
    });
    this.route('partner', function () {
      this.route('show', { path: '/:id' });
    });
    this.route('delegate', function () {
      this.route('show', { path: '/:id' });
    });
    // this.route('mainevents', function() {
    //   this.route('show', { path: '/:id' });
    // });
    this.route('ssdelegate', function () {
      this.route('show', { path: '/:id' });
    });
  });

  exports['default'] = Router;
});