define('essma/controllers/notification/index', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    columns: computed(function () {
      return [{
        label: 'Title',
        valuePath: 'title'
      }, {
        label: 'Message',
        valuePath: 'message'
      }];
    }),

    actions: {
      transitionToDetail: function transitionToDetail(row) {
        this.transitionToRoute('notification.show', row.get('content'));
      }
    }
  });
});