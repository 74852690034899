define('essma/controllers/events/index', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    columns: computed(function () {
      return [{
        label: 'Name',
        valuePath: 'name',
        cellComponent: 'main-cell'
      }, {
        label: 'Date',
        valuePath: 'prettyDate'
      }, {
        label: 'Tasks',
        valuePath: 'tasks.length'
      }, {
        label: 'Document',
        valuePath: 'documentLink',
        cellComponent: 'task-options'
      }];
    }),

    actions: {
      transitionToEventsShow: function transitionToEventsShow(row) {
        var event = row.get('content');
        this.transitionToRoute('events.show', event);
      }
    }
  });
});