define('essma/components/task-options', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      onClose: function onClose(shouldClose) {
        if (this.get('canClose')) {
          this.toggleProperty('canClose');
          return true;
        }

        return false;
      },

      toggleCompletion: function toggleCompletion(task) {
        this.toggleProperty('row.content.isCompleted');

        if (this.get('row.content.isCompleted') && !this.get('row.content.completedDate')) {
          this.set('row.content.completedDate', new Date());
        }
      },

      save: function save(dropdown) {
        return regeneratorRuntime.async(function save$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get('row.content').save());

            case 2:

              this.set('canClose', true);
              dropdown.actions.close();

            case 4:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      rollback: function rollback(dropdown) {
        this.get('row.content').rollbackAttributes();

        this.set('canClose', true);
        dropdown.actions.close();
      }
    }
  });
});