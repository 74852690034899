define('essma/models/mainevents', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    exports['default'] = _emberData['default'].Model.extend({
        name: attr('string'),
        date: attr('string'),
        location: attr('string'),
        eventurl: attr('string'),
        image: attr('string'),
        linkImage: attr('string')
    });
});