define('essma/controllers/mainevents/index', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    columns: computed(function () {
      return [{
        label: 'Name',
        valuePath: 'name'
      }, {
        label: 'Date',
        valuePath: 'date'
      }, {
        label: 'Location',
        valuePath: 'location'
      }, {
        label: 'URL',
        valuePath: 'eventurl'
      }, {
        label: 'Image Uploaded',
        valuePath: 'image',
        sortable: false,
        cellComponent: 'task-options'
      }];
    }),
    actions: {
      transitionToDetail: function transitionToDetail(row) {
        this.transitionToRoute('mainevents.show', row.get('content'));
      }
    }
  });
});