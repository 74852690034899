define('essma/models/business', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    exports['default'] = _emberData['default'].Model.extend({
        name: attr('string'),
        description: attr('string'),
        contact: attr('string'),
        email: attr('string'),
        web: attr('string'),
        image: attr('string'),
        linkImage: attr('string')
    });
});