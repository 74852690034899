define('essma/mixins/table-common', ['exports', 'ember', 'ember-light-table', 'ember-concurrency'], function (exports, _ember, _emberLightTable, _emberConcurrency) {
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = _ember['default'].Mixin.create({
    store: inject.service(),

    page: 0,
    limit: 20,
    dir: 'asc',
    sort: 'firstName',

    isLoading: computed.oneWay('fetchRecords.isRunning'),
    canLoadMore: true,
    enableSync: true,

    model: null,
    meta: null,
    columns: null,
    table: null,

    init: function init() {
      this._super.apply(this, arguments);

      this.setupTable();
    },

    setupTable: function setupTable() {
      var table = new _emberLightTable['default'](this.get('columns'), this.get('model'), { enableSync: this.get('enableSync') });
      var sortColumn = table.get('allColumns').findBy('valuePath', this.get('sort'));

      // Setup initial sort column
      if (sortColumn) {
        sortColumn.set('sorted', true);
      }

      this.set('table', table);
    },

    tableReser: observer('model.[]', function () {
      this.setupTable();
    }),

    actions: {
      onScrolledToBottom: function onScrolledToBottom() {
        if (this.get('canLoadMore')) {
          this.incrementProperty('page');
          this.get('fetchRecords').perform();
        }
      },

      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath'),
            canLoadMore: true,
            page: 0
          });
          this.get('model').clear();
        }
      }
    }
  });
});