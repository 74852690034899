define('essma/components/phone-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].TextField.extend({

    dasherizeDigits: _ember['default'].observer('value', function () {
      var valueString = this.get('value').replace(/\D+/g, '');

      this.set('value', valueString);
    })

  });
});