define('essma/components/sortable-table', ['exports', 'ember', 'essma/mixins/table-common', 'ember-concurrency'], function (exports, _ember, _essmaMixinsTableCommon, _emberConcurrency) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_essmaMixinsTableCommon['default'], {
    query: '',

    // No need for `enableSync` here
    enableSync: false,

    isLoading: computed.or('fetchRecords.isRunning', 'setRows.isRunning').readOnly(),

    // Sort Logic
    sortedModel: computed.sort('model', 'sortBy').readOnly(),
    sortBy: computed('dir', 'sort', function () {
      return [this.get('sort') + ':' + this.get('dir')];
    }).readOnly(),

    fetchRecords: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('filterAndSortModel').perform();

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).on('init'),

    setRows: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(rows) {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.get('table').setRows([]);
            this.get('table').setRows(rows);

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),

    filterAndSortModel: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var debounceMs = arguments.length <= 0 || arguments[0] === undefined ? 200 : arguments[0];
      var query, model, valuePath, result;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return (0, _emberConcurrency.timeout)(debounceMs);

          case 2:
            query = this.get('query');
            model = this.get('sortedModel');
            valuePath = this.get('selectedFilter.valuePath');
            result = model;

            if (query !== '') {
              result = model.filter(function (m) {
                return m.get(valuePath).toLowerCase().includes(query.toLowerCase());
              });
            }

            context$1$0.next = 9;
            return this.get('setRows').perform(result);

          case 9:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),

    actions: {
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath')
          });

          this.get('filterAndSortModel').perform(0);
        }
      },

      onSearchChange: function onSearchChange() {
        this.get('filterAndSortModel').perform();
      }
    }
  });
});
// debounce