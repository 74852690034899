define('essma/helpers/w', ['exports', 'ember-cli-string-helpers/helpers/w'], function (exports, _emberCliStringHelpersHelpersW) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersW['default'];
    }
  });
  Object.defineProperty(exports, 'w', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersW.w;
    }
  });
});