define('essma/routes/business/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var id = _ref.id;

      if (id === 'new') {
        return this.store.createRecord('business', {});
      }

      return this.store.findRecord('business', id);
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.get('controller').willTransition(transition);
      }
    }
  });
});