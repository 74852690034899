define('essma/controllers/business/index', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    columns: computed(function () {
      return [{
        label: 'Company Name',
        valuePath: 'name'
      }, {
        label: 'Contact Person',
        valuePath: 'contact'
      }, {
        label: 'Email',
        valuePath: 'email'
      }, {
        label: 'Web',
        valuePath: 'web'
      }, {
        label: 'Image Uploaded',
        valuePath: 'image',
        sortable: false,
        cellComponent: 'task-options'
      }];
    }),
    actions: {
      transitionToDetail: function transitionToDetail(row) {
        this.transitionToRoute('business.show', row.get('content'));
      }
    }
  });
});