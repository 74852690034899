define('essma/models/event', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    date: attr('date'),
    documentLink: attr('string'),

    tasks: hasMany(),

    prettyDate: computed('date', function () {
      return moment(this.get('date')).format('DD/MM/YYYY');
    })
  });
});