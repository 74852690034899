define('essma/models/task', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({

    isTask: true,

    taskId: attr('number'),
    date: attr('date'),
    day: attr('string'),
    time: attr('string'),
    procedure: attr('string'),
    action: attr('string'),
    responsible: attr('string'),
    documentLink: attr('string'),

    fileName: attr('string'),

    isAlert: attr('boolean'),
    isCompleted: attr('boolean'),
    completedBy: attr('string'),
    completedDate: attr('date'),

    event: belongsTo(),

    prettyDate: computed('date', function () {
      return moment(this.get('date')).format('DD/MM/YYYY');
    }),

    prettyDateWithDay: computed('date', 'day', function () {
      if (this.get('day')) {
        return this.get('prettyDate') + (' (' + this.get('day') + ')');
      }

      return this.get('prettyDate');
    })
  });
});